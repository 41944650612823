@mixin shop-lead {
  &:after {
    content: 'Zobrazit v\00a0 e\2011shopu';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 52px;
    background-color: rgba(84, 81, 81, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: opacity .4s, transform .2s;
    text-shadow: 1px 1px 2px black;
    color: white;
  }
  &:hover:after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}