$header_y_padding: map-get($spacers,3);
$header_out_color: #7bad4d;
$header_logo_height: 60px;
$header_logo_offset: 10px;
$header_height: $header_logo_height + $header_logo_offset*2;

.header {
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    justify-self: center;
    margin: 0;
    color: #ebd8a1;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  }
  &-nav {
    @include media-breakpoint-down(xs) {
      position: absolute;
      display: flex;
      flex-flow: column;
      top: 100%;
      left: 0;
      width: 100%;
      height: calc(100vh - 100%);
      background: rgba(0, 0, 0, 0.9);
      padding: $grid-gutter-width $grid-gutter-width/2;
      transition: .2s transform;
      transform: scaleX(0);
      transform-origin: right;
      will-change: transform;
      &.toggled {
        transform: scaleX(1);
      }
    }
    a {
      position: relative;
      color: white;
      margin-bottom: 1rem;
      font-weight: bold;
      font-size: 1.5rem;
      &.active, &:hover {
        text-decoration: none;
        @include media-breakpoint-down(xs) {
          color: $secondary;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      margin: 0 ($grid-gutter-width/4)*-1;
      padding-bottom: $header_y_padding;
      a {
        margin: 0 $grid-gutter-width/4;
        padding-bottom: $header_y_padding;
        font-size: 1rem;
        &:after {
          content: '';
          position: absolute;
          bottom: -0.15rem;
          left: 0;
          right: 0;
          height: 0;
          background-color: $secondary;
          transition: .2s height;
          will-change: height;
        }
        &.active, &:hover {
          &:after {
            height: 5px;
          }
        }
      }
    }
    &-toggle {
      height: 35px;
      cursor: pointer;
      position: relative;
      div {
        background: white;
        height: 3px;
        width: 35px;
        pointer-events: none;
        &:nth-child(2) {
          margin: 5px 0;
          transition: .2s opacity;
        }
        &:not(:nth-child(2)) {
          transition: .2s transform;
        }
      }
      &.toggle-toggled {
        div:first-child {
          transform: rotate(45deg) translate(6px, 6px);
        }
        div:nth-child(2) {
          opacity: 0;
        }
        div:last-child {
          transform: rotate(-45deg) translate(5px, -5px);
        }
      }
      &:after {
        content: '';
        position: absolute;
        left: -.5rem;
        top: -.5rem;
        bottom: -.5rem;
        right: -.5rem;
        border-radius: 50%;
        background-color: rgba(0,0,0,0.25);
        transition: .3s transform;
        will-change: transform;
        transform: scale(0);
        z-index: -1;
      }
      &:hover {
        &:after {
          transform: scale(1);
        }
      }
    }
  }
  .logo {
    height: $header_logo_height;
    margin: $header_logo_offset 0;
  }
}

/*.breadcrumb {
  background-color: $primary;
  ul {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin: 0;
    font-size: 10px;
  }
  li {
    color: white;
    &:not(:last-child):after {
      content: '/';
      margin: 0 4px;
    }
    & a {
      color: $secondary;
    }
  }
}*/
