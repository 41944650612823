.bg {
  &-no-repeat {
    background-repeat: no-repeat;
  }
  &-position {
    &-y {
      //&-top { background-position-y: 0; }
      &-center { background-position-y: 50%; }
      &-bottom { background-position-y: 100%; }
    }
    &-x {
      //&-left { background-position-x: 0; }
      &-center { background-position-x: 50%; }
      &-right { background-position-x: 100%; }
    }
  }
  &-size {
    &-cover { background-size: cover; }
    &-100 { background-size: 100%; }
  }
}