$page-line-width-xs: 10px;
$page-line-width-lg: 17px;

.page {
  background-color: white;
  box-shadow: 4px 4px 12px rgba(0,0,0,.2);
  position: relative;
  &:not(.guide) {
    padding-right: $page-line-width-xs;
    &:after {
      content: '';
      height: 100%;
      width: $page-line-width-xs;
      background-image: url(/img/line.jpg);
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    &:not(.guide) {
      padding-right: $page-line-width-lg;
      &:after {
        width: $page-line-width-lg;
      }
    }
  }
}