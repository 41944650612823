$container-panel-breakpoints: (
    xs: 0,
    sm: 576px,
    lg: 992px,
    xl: 1200px
);

$container-panel-max-widths: (
    sm: 540px,
    lg: 960px,
    xl: 1140px
);

.container-panel {
  @include make-container($gutter: $grid-gutter-width);
  @include make-container-max-widths(
          $max-widths: $container-panel-max-widths,
          $breakpoints: $container-panel-breakpoints
  )
}