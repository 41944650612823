.position-absolute {
  &-full {
    @extend .position-absolute;
    left:0;
    top:0;
    bottom:0;
    right:0;
  }
  &-center {
    @extend .position-absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}

.href-like {
  cursor: pointer;
  transition: .2s;
  &:hover {
    color: lighten(black,60);
  }
}

.flexer {
  display: none;
  flex-basis: 100%;
  @include media-breakpoint-between(md,lg) {
    display: block;
  }
}