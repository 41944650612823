$book-cover-offset: $grid-gutter-width*0.8;

@mixin book-offset($direction,$offset) {
  .book-offset {
    &:not(div) {
      margin-#{$direction}: $offset;
    }
    &-sm:not(div) {
      margin-#{$direction}: $offset*0.6;
    }
    &-lg:not(div) {
      margin-#{$direction}: $offset*1.5;
    }
  }
  div.book-offset {
    padding-#{$direction}: $offset;
    &-sm {
      padding-#{$direction}: $offset*0.6;
    }
    &-lg {
      padding-#{$direction}: $offset*1.5;
    }
  }
}

/*.col-lg {
  &-4,&-5,&-6 {
    .book .flex-column {
      > div:first-child > a, > div:first-child > div {
        margin: 0 auto;
        display: block;
      }
      > div:first-child > a,
      > div:first-child > a > div,
      > div:first-child > div {
        &, & img {
          max-width: 175px;
        }
      }
    }
  }
}*/
.book .flex-column {
  > div:first-child > a, > div:first-child > div {
    margin: 0 auto;
    display: block;
  }
}
.book:not(.book-without-img) .flex-column {
  > div:first-child > a, > div:first-child > div {
    margin: 0 auto;
    display: block;
  }
  > div:first-child > a,
  > div:first-child > a > div,
  > div:first-child > div {
    & {
      max-width: 175px;
    }
    & img {
      width: 100%;
      max-width: 175px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .book:not(.book-without-img) > div > div:first-child > div:not(.sticker):not(.tag),.book > div > div:first-child > a {
    max-width: 175px;
    margin: 0 auto;
    display: block;
  }
}


.book {

  /*a {
    display: contents;
  }*/

  &-wrapper {
    //@todo: not sure about this
    width: 100%;
    .flex-column &, .flex-column-reverse & {
      //@todo: not sure about this
       //width: 100%;
    }
    @include media-breakpoint-down(lg) {
      text-align: center;
      &:only-child .book-cover {
        //display: inline-flex !important;
        display: inline-block !important;
      }
    }

    a {
      @include shop-lead;
    }

  }

  &-description {
    &-lines {
      border-top: 1px solid currentColor;
      border-bottom: 1px solid currentColor;
      margin: map_get($spacers,2) 0 .35rem;
      padding: 1rem 0;
      .book-has-title-over & {
        border-top: 0;
      }
    }
  }

  a > .book-cover {
    transition: .2s;
    will-change: transform;
  }

  a:hover > .book-cover {
    //transform: scale(1.05);
    opacity: 0.75;
    transform: scale(0.972);
  }

  &-content-wrap {
    max-width: 100%;
  }

  &-cover {
    max-width: 100%;
    img {
      flex-shrink: 0;
    }
  }


  //@TODO: zeptat se martiny ejstli je to ok
 /* @include media-breakpoint-up(lg) {*/
    &-overflow {
      &-middle { margin: -20px 0;}
      &-top { margin: -20px 0 0; }
      &-bottom { margin: 0 0 -20px; }
    }

    &-img-large {
      .book-overflow {
        &-middle { margin: -75px 0;}
        &-top { margin: -75px 0 0; }
        &-bottom { margin: 0 0 -75px; }
      }
    }
  /*}*/

  [class*=flex-row] {
    .book-cover {
      //min-width: 150px;
      &, & img {
        width: 175px;
      }
    }
  }

  &-img-large {
    [class*=flex-row] {
      .book-cover {
        min-width: 275px;
      }
    }
  }

  .block.col-lg-12 > & [class*=flex-row] {
    .book-cover {
      //min-width: 215px;
    }
  }

  [class*=flex-column] {
    .book-cover {
      justify-content: center;
      margin-bottom: 0.5rem;

      img[class*=shadow] {
        margin-bottom: 0.5rem;
      }
      @include media-breakpoint-up(lg) {
        //height: 210px;
      }
    }
    img {
      /*width: 150px;
      align-self: flex-end;*/
    }
  }

  @media (min-width: 461px) and (max-width: 767px) {
    .flex-row {
      @include book-offset('right', $book-cover-offset*0.5);
      &-reverse {
        @include book-offset('left', $book-cover-offset*0.5);
      }
    }
  }

  @media (min-width: 881px) {
    .flex-row {
      @include book-offset('right', $book-cover-offset*0.5);
      &-reverse {
        @include book-offset('left', $book-cover-offset*0.5);
      }
    }
  }


  h3 {
    font-family: $font-family-headline;
    font-weight: bold;
    margin-bottom: 0.25rem;
    font-size: 1.3rem;
    text-transform: uppercase;
  }

  h4 {
    font-family: $font-family-headline;
    font-weight: bold;
    margin-bottom: 0.25rem;
    font-size: 1rem;
  }

  hr {
    border-color: currentColor;
  }

  @include media-breakpoint-down(xs) {
    .block > div:not(:last-of-type) & {
      margin-bottom: 4rem;
    }
    .book-cover {
      img {
        width: 150px;
      }
    }
  }

  @media (max-width: 460px) {
    &-overflow {
      margin: -20px 0 0;
    }
    >div:not([class*=flex-column]) {
      flex-direction: column !important;
      .book {
        &-wrapper {
          width: 100%;
        }
        &-cover {
          justify-content: center;
          margin: 0 auto 0.75rem;
          //margin-bottom: 0.75rem;
          &.shadow {
            margin-bottom: 1rem;
          }
        }
      }
    }
    //.flex-row, .flex-row-reverse
  }

}

@media (min-width: 768px) and (max-width: 880px) {
  .block:not(.col-lg-12) {
    .book .flex-row-reverse, .book .flex-row {
      flex-direction: column !important;
      .book-cover {
        height: 210px;
        max-width: unset;
        flex-basis: auto;
        min-width: unset;
        padding: 0;
        justify-content: center;
        margin-bottom: 0.5rem;
        img {
          max-width: 150px;
          align-self: flex-end;
          max-height: 100%;
        }
      }
    }
  }
}
.block.col-lg-12 {
  .book {
    @media (min-width: 461px) {
      .flex-row {
        @include book-offset('right', $book-cover-offset*0.5);
        &-reverse {
          @include book-offset('left', $book-cover-offset*0.5);
        }
      }
    }
  }
}
