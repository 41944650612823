$tag-offset: 5px;

.tag {
  @extend .position-absolute;
  bottom: 10px;
  padding: 4px 8px;
  color: white;
  font-weight: bold;
  font-size: 0.8rem;
  border-radius: 4px;
  box-shadow: 2px 2px 3px rgba(0,0,0,0.25);
  background-color: $primary;
  left: 0;
  line-height: 1.25;
  max-width: calc(100% - #{$tag-offset});
  &-lg {
    font-size: 1.08rem;
    padding: 6px 10px;
  }
}

//@todo: proc to je v tag.scss
@media (min-width: 461px) {
  .col-12 > .block-inner-wrap > .book {
    .flex-row-reverse, .flex-row {
      .book-wrapper {
        width: 175px;
      }
      &.justify-content-center {
        .book-wrapper {
          margin: 0 auto;
        }
      }
    }
  }
  .book {
    .flex-row-reverse, .flex-row {
      .book-wrapper {
        //TODO: IE FIX na auto, otestovat, width: 100%;
        width: auto;
        //@TODO: toto mozna rozbije male fotky
        min-width: 175px;
      }
      .tag {
        left: 0;
        right: auto;
        bottom: 5px;
      }
    }
  }
}

@media (min-width: 881px) {
  .book {
    .flex-row-reverse {
      .tag {
        left: auto;
        right: $tag-offset * -1;
        bottom: -10px;
      }
    }
    .flex-row {
      .tag {
        left: $tag-offset * -1;
        right: auto;
        bottom: -10px;
      }
    }
  }
}
