.text {
  h1,h2,h3,h4,h5,h6 {
    font-size: inherit;
    margin-bottom: 0;
    line-height: inherit;
    font-weight: inherit;
  }
  h3,h4,h5 {
    /*margin-bottom: 1.3rem;
    &:not(:first-child) {
      margin-top: 1.5rem;
    }*/
  }
  p {
    margin-bottom: 1.3rem;
  }
  ul {
    padding-left: 0;
  }
  blockquote {
    &:before {
      content: ' ';
      height: 6px;
      width: 80px;
      max-width: 75%;
      display: block;
      background: currentColor;
      margin-bottom: 0.4rem;
    }
  }
  &-shadow {
    text-shadow: 1px 1px 4px rgba(0,0,0,0.45);
    &-lg {
      text-shadow: 1px 1px 8px rgba(0,0,0,0.65);
    }
    &-sm {
      text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
    }
  }
}