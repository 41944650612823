.guide {
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
    transition: opacity .2s;
    &.active {
      color: $primary;
    }
    &:hover {
      //background-color: rgba(0,0,0,0.2);
      //color: $primary;
      /*.guide-counter {
        color: $primary;
      }*/
      opacity: 0.5;
    }
  }
  &-counter {
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 0.75rem;
  }
  &-list {
    /*overflow: auto;
    height: 380px;
    position: relative;*/
  }
  ul {
    padding-left: 0;
    font-family: 'Montserrat', sans-serif;
    /*&::-webkit-scrollbar {
      width: 8px;
    }*/
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }/*
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }*/
  }
  &-title {
    div:nth-child(2) {
      color: #d0af50;
    }
  }
}