.event {
  &-list {
    font-family: 'Montserrat', sans-serif;
    p {
      margin-bottom: 0;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
    }
  }
  &-item {
    margin-bottom: 0.75rem;
    display: flex;
    align-items: flex-start;
  }
  &-date {
    //font-family: 'Bebas Neue', cursive;
    padding: 0.25rem 0.4rem 0.12rem;
    color: white;
    font-size: 1rem;
    width: 4rem;
    text-align: center;
    margin-top: 3px;
  }
  &-content {
    width: calc(100% - 4rem);
  }
}