
@mixin stars-container($imagePath) {
  background-image: url($imagePath);
  background-repeat: repeat-x, no-repeat, space;
  background-size: 24px;
  background-position: 0 4px;
}


$rating_cyan: #2591aa;
.rating {
  display: flex;
  align-self: flex-start;
  @extend .mt-1, .mb-3, .align-items-center;

 /* .book .flex-row &,
  .book .flex-row-reverse & {
    @media (min-width: $breakpoint-sm), (min-width: 881px) {
      position: absolute;
      bottom: ($grid-gutter-width/2) *-1;;
      right: ($grid-gutter-width/2);
    }
  }*/
  &-col {
    z-index: 2;
    @extend .position-relative;
  }
  &-author {
    @extend .position-absolute;
    font-size: 0.65rem;
    bottom: -16px;
    left: 4px;
  }
  &-value {
    background-color: $rating_cyan;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 8px 10px;
    z-index: 5;
    position: relative;
    @extend .shadow-sm;
    &-corner {
      height: 9px;
      width: 16px;
      transform: rotate(40deg);
      background: #145b6b;
      position: absolute;
      top: -4px;
      left: -4px;
      z-index: -1;
    }
  }
  &-line {
    height: 26px;
    width: 132px;
    position: static;
    z-index: 8;
    background: white;
    @extend .shadow-sm;
  }
  &-stars {
    height: 100%;
    width: calc(100% - 18px);
    margin: 0 auto;
    position: relative;
    @include stars-container('/img/assets/elements/rating-empty.png');
    > div {
      position: absolute;
      left:0;
      top:0;
      bottom:0;
      right:0;
      @include stars-container('/img/assets/elements/rating-filled.png');
    }
  }
}


//bookfix
.book-has-rating {
  position: relative;
  /*.flex-row,.flex-row-reverse {
    @media (min-width: $breakpoint-sm) and (max-width: map_get($grid-breakpoints,lg)) {
      padding-bottom: 45px !important;
      .rating {
        position: absolute;
        left: 0;
        bottom: 12px;
        right: auto;
      }
    }
    @media (min-width: 881px) {
      .rating {
        position: absolute;
        bottom: ($grid-gutter-width/2) *-1;
        right: ($grid-gutter-width/2);
      }
    }
  }*/
}

// naklon
/*.rating {
  perspective: 100px;
  perspective-origin: 25% 75%;
  &-value {
    transform: rotateX(-10deg) rotateY(-10deg);
    &-corner {
      transform: rotate(48deg);
      top: -5px;
      left: -5px;
    }
  }
}*/