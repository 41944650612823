
.image {
  width: 100%;
  @include media-breakpoint-down(xs) {
    margin-left: auto;
    margin-right: auto;
  }
  &-has-content {
    @include media-breakpoint-up(lg) {
      margin-bottom: $grid-gutter-width;
      &-from-top {
        margin-bottom: $grid-gutter-width/2;
      }
    }
  }
  a {
    img {
      transition: .2s;
      will-change: transform;
      &:hover {
        opacity: 0.75;
        transform: scale(0.972);
      }
    }
  }
  &-lead-to-shop {
    a {
      @include shop-lead;
    }
  }
  &-content {
    color: white;
    z-index: 111;
    position: absolute;
    bottom: -1 * $grid-gutter-width;
    &-holder {
      padding: 0.5rem 0.75rem;
      background: rgba(0,0,0,0.75);
    }

    &-overflown {
      right: $grid-gutter-width/2;
      left: -1 * $grid-gutter-width/2;
    }

    //// tucked start
    &-tucked {
      left: -12px;
      right:0;
      em {

      }
      h2,h3,h4,h5,h6 {
        margin: 4px 0 2px;
      }
      text-align: center;
      .image-content-holder {
        padding: 0.75rem 0.75rem;
        &:after {
          content: '';
          position: absolute;
          background-color: inherit;
          height: 100%;
          width: 12px;
          right: -7px;
          top: 0;
          transform: skew(-5deg, 0);
        }
      }
    }
    &-tuck {
      &-left {
        position: absolute;
        top: -16px;
        filter: brightness(0.75);
        z-index: -1;
        left: 0;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
      }
    }
    //// tucked end

    p {
      font-family: 'Montserrat', sans-serif;
      font-size: 0.8rem;
      margin-bottom: 0;
    }
    > img, > a {
      flex-shrink: 0;
    }
    &.image-content-top {
      bottom: auto;
      top: calc(100% - #{$grid-gutter-width/2});
    }
    &, &.image-content-top {
      @include media-breakpoint-down(xs) {
        bottom: auto;
        top: auto;
        right: auto;
        left: auto;
        position: relative;
        margin-top: -1 * $grid-gutter-width/2;
        margin-left: -1 * $grid-gutter-width/4;
        margin-right: 3px;
      }
    }
  }
}
