$spacer: 1rem;
$enable-rounded: false;
$font-size-base: 1rem;

$grid-breakpoints: (xs:0, lg:768px);
$container-max-widths: (lg:960px);
$breakpoint-sm: 460px;

$grid-gutter-width: 40px;
$mobile-gutter-width: 24px;

$font-family-slab: 'Roboto Slab', serif;
$font-family-headline: 'Montserrat', sans-serif;

$primary: #7bad4d;
$primary-light: #6e9a46;
$secondary: #ebd8a1;

$box-shadow-sm:               0 .125rem .25rem rgba(black, .25);
$box-shadow:                  0 .25rem .5rem rgba(black, .45);
$box-shadow-lg:               0 .5rem 1rem rgba(black, .25);

$h1-font-size:                $font-size-base * 3.25;
$h2-font-size:                $font-size-base * 2.5;
$h3-font-size:                $font-size-base * 2;
$h4-font-size:                $font-size-base * 1.75;
$h5-font-size:                $font-size-base * 1.5;
$h6-font-size:                $font-size-base;

$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2),
    6: ($spacer * 3),
    7: ($spacer * 4),
    8: ($spacer * 5)
);

$border-radius: .5rem;
$border-radius-lg: .85rem;
$border-radius-sm: .25rem;
$light: #f1f3f5;
$dark: #c7c7c7;

/*
$primary = rgb(47,156,51);
$secondary = rgb(147,177,52);
$purple = rgb(131,59,143);
$yellow = rgb(255,223,0);
$blue = rgb(1,119,191);
$green = rgb(47,156,51);
$red = rgb(228,5,31);*/

/*
$theme-colors: map-merge(
                (
                        "primary":    $primary,
                        "secondary":  $secondary,
                        "success":    $success,
                        "info":       $info,
                        "warning":    $warning,
                        "danger":     $danger,
                        "light":      $light,
                        "dark":       $dark
                ),
                $theme-colors
);*/