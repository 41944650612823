
$footer-padding: map-get($spacers,5);
$footer-largest-logo-height: 76px;
$footer-addon-height: 30px;
$footer-logo-offset: $grid-gutter-width/4;
$footer-base-height: $footer-logo-offset*2 + $footer-addon-height + $footer-largest-logo-height;

.footer {
  &-container {
    padding-top: $footer-padding;
    padding-bottom: $footer-padding;
  }
  &-logo {
    padding: 0 $footer-logo-offset;
    margin: $footer-logo-offset 0;
    flex-shrink: 0;
    &-wrap {
      margin: 0 #{$footer-logo-offset * -1}
    }
  }
  &-addon {
    height: $footer-addon-height;
  }
}

.logo {
  &-superbrands {
    height: $footer-largest-logo-height;
    width: 127px;
  }
  &-dobrovsky {
    height: 52px;
  }
  &-shoproku {
    height: 60px;
  }
  &-shoproku-lg {
    height: 80px;
  }
}
/*
@include media-breakpoint-up(lg) {
  .logo {
    &-superbrands {
      height: 76px;
    }
    &-dobrovsky {
      height: 52px;
    }
    &-shoproku {
      height: 60px;
    }
  }
}*/