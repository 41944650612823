.title {
  font-weight: 600;
  z-index: 14;
  font-size: 1.25rem;
  @extend .position-relative, .d-inline-block;
  &-with {
    &-background {
      padding: 0.5rem 1.5rem 0.35rem;
      background-color: $dark;
      color: white;
    }
    &-underline {
      &:after {
        content: '';
        position: absolute;
        height: 2px;
        bottom: -4px;
        left: 1em;
        right: 1em;
        background-color: currentColor;
      }
    }
  }
  &-backdrop {
    @extend .position-absolute, .h-100, .w-100;
    top: 5px;
    left: 5px;
    background-color: inherit;
    opacity: 0.5;
    z-index: -1;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
  }
}