$sticker_size: 60px;
$sticker_offset_size: $sticker_size/3;

$sticker_size_lg: 100px;
$sticker_offset_size_lg: $sticker_size_lg/3;
.sticker {
  @extend .position-absolute;
  background-color: $secondary;
  border-radius: 100%;
  top: $sticker_offset_size * -1;
  left: $sticker_offset_size * -1;
  height: $sticker_size;
  width: $sticker_size;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  line-height: 1.25;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  transform: rotate(-8deg);
  font-size: 0.7rem;
  z-index: 20;
  &-top-right {
    left: auto;
    right: $sticker_offset_size * -1;
    transform: rotate(8deg);
  }
  &-bottom-left {
    top: auto;
    bottom: $sticker_offset_size/6 * -1;
  }
  &-bottom-right {
    top: auto;
    left: auto;
    right: $sticker_offset_size * -1;
    bottom: $sticker_offset_size/6 * -1;
    transform: rotate(8deg);
  }
  &-lg {
    font-size: 1.1rem;
    top: $sticker_offset_size_lg * -1;
    left: $sticker_offset_size_lg * -1;
    height: $sticker_size_lg;
    width: $sticker_size_lg;
    &.sticker-top-right {
      left: auto;
      right: $sticker_offset_size_lg * -1;
      transform: rotate(8deg);
    }
    &.sticker-bottom-left {
      top: auto;
      bottom: $sticker_offset_size_lg/6 * -1;
    }
    &.sticker-bottom-right {
      top: auto;
      left: auto;
      right: $sticker_offset_size_lg * -1;
      bottom: $sticker_offset_size_lg/6 * -1;
      transform: rotate(8deg);
    }
  }
}
