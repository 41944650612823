.font {
  &-montserrat {
    &, & p { font-family: 'Montserrat', sans-serif; }
  }
  &-roboto-slab {
    &, & p { font-family: 'Roboto Slab', serif; }
  }
  &-cormorant {
    &, & p { font-family: 'Cormorant Infant', serif; }
  }
  &-enriqueta {
    &, & p { font-family: 'Enriqueta', serif; }
  }
  &-oswald {
    &, & p {
      font-family: 'Oswald', sans-serif;
    }
    &.font-lg { font-size: $font-size-base*1.2; }
    &.font-xl { font-size: $font-size-base*1.65; }
    &.font-xxl { font-size: $font-size-base*2.25; }
    &.font-xxxl { font-size: $font-size-base*2.75; }
    &.font-mg , h1{ font-size: $font-size-base*4; }
    @include media-breakpoint-up(lg) {
      &.font-xs { font-size: $font-size-base*1; }
      &.font-sm { font-size: $font-size-base*1.25; }
      &.font-lg { font-size: $font-size-base*1.75; }
      &.font-xl { font-size: $font-size-base*2.5; }
      &.font-xxl { font-size: $font-size-base*4.5; }
      &.font-xxxl { font-size: $font-size-base*5.5; }
      &.font-mg, h1 { font-size: $font-size-base*7; }
    }
  }
  &-pacifico {
    &, & p { font-family: 'Pacifico', cursive; }
  }

  /** ---- sizes ----- */


  &-lg { font-size: $font-size-base*1.1; }
  &-xl { font-size: $font-size-base*1.5; }
  &-xxl { font-size: $font-size-base*2; }
  &-xxxl { font-size: $font-size-base*2.25; }
  &-mg { font-size: $font-size-base*2.75; }
  @media (min-width: 480px) {
    &-lg { font-size: $font-size-base*1.2; }
    &-xl { font-size: $font-size-base*1.65; }
    &-xxl { font-size: $font-size-base*2.25; }
    &-xxxl { font-size: $font-size-base*2.75; }
    &-mg { font-size: $font-size-base*3.25; }
  }
  @include media-breakpoint-up(lg) {
    &-xs { font-size: $font-size-base*0.5; }
    &-sm { font-size: $font-size-base*0.8; }
    &-md { font-size: $font-size-base; }
    &-lg { font-size: $font-size-base*1.3; }
    &-xl { font-size: $font-size-base*2; }
    &-xxl { font-size: $font-size-base*2.5; }
    &-xxxl { font-size: $font-size-base*3.25; }
    &-mg { font-size: $font-size-base*4.25; }
  }

  &-bold { font-weight: bold; }
  &-light { font-weight: 300; }
}

.line-height {
  &-xxs { line-height: 0.75; }
  &-xs { line-height: 1; }
  &-sm { line-height: 1.25; }
  &-md { line-height: 1.5; }
  &-lg { line-height: 1.75; }
  &-xl { line-height: 2; }
}