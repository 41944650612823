.custom-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: $primary;
  outline: 1px solid $primary;
}

.custom-scrollbar {
  scrollbar-base-color: #000;
  scrollbar-face-color: $primary;
  scrollbar-3dlight-color: #000;
  scrollbar-highlight-color: #000;
  scrollbar-track-color: #000;
  scrollbar-arrow-color: $primary;
  scrollbar-shadow-color: #000;
  scrollbar-dark-shadow-color: #000;
}


.cookiebar {
  @extend .font-roboto-slab;
  position: fixed;
  max-width: 500px;
  width: calc(100% -  #{$mobile-gutter-width});
  left: $mobile-gutter-width/2;
  bottom:  $mobile-gutter-width/2;
  background: rgba(rgba(0, 0, 0, 0.8),0.9);
  padding: 1rem;
  border-radius: 4px;
  color: #cdcdcd;
  font-size: 0.85rem;
  z-index: 11500;
  @include media-breakpoint-up(lg) {
    left: $grid-gutter-width/2;
    bottom:  $grid-gutter-width/2;
  }
  p {
    color: #cdcdcd;
  }
  a:hover {
    color: white;
  }
  h3 {
    font-size: 1.15rem;
    margin-top: 0.5rem;
    margin-bottom: 0.15rem;
    font-weight: bold;
  }
  button {
    @extend .btn, .btn-sm, .btn-dark;
  }
  table {
    @extend .table-responsive;
    td, th {
      vertical-align: top;
      &:not(:first-child):not(:last-child) {
        padding: 0 8px;
        white-space: nowrap;
      }
    }
    td:last-child {
      min-width: 160px;
    }
  }
  &-detail {
    max-height: 300px;
    overflow: auto;
    padding-right: 8px;
    @extend .custom-scrollbar;
  }
  &-perex {
    display: flex;
    align-items: center;
    @include media-breakpoint-up(lg) {
      > div:first-child {
        padding-right: 8px;
      }
    }
    @include media-breakpoint-down(xs) {
      flex-flow: column;
      div:nth-child(2) {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
    button {
      width: 100%;
      &:first-child {
        @extend .btn-primary;
        margin-bottom: .25rem;
      }
    }
  }
}
