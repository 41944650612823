
@import "bootstrap/bootstrap-modificator";
@import "bootstrap/bootstrap";
@import "bootstrap/extends";

@import "common/backgrounds";
@import "common/mixins";

@import "components/cookie";
@import "components/panelContainer";
@import "components/fonts";
@import "components/guide";
@import "components/title";
@import "components/book";
@import "components/image";
@import "components/page";
@import "components/text";
@import "components/event-list";
@import "components/header";
@import "components/footer";
@import "components/sticker";
@import "components/tag";
@import "components/rating";

html {
  font-size: 14px;
}

p, ul {
  font-family: $font-family-slab;
  img {
    max-width: 100%;
  }
}
p, ul {
  &:last-child { margin-bottom: 0; }
}
h6 {
  margin-bottom: 0;
  font-family: inherit;
  line-height: 1.5;
}

body {
  font-family: 'Montserrat', sans-serif;
  //overflow: hidden;
}

.main {
  min-height: calc(100vh - #{$header_height} - #{$footer-base-height} - #{$footer-padding*2});
}


.page a:not(.stretched-link) {
  text-decoration: underline;
  transition: opacity .2s;
  &:hover {
    filter: brightness(0.8);
  }
}

.tag {
  &-pdf {
    padding: 2px 5px;
    right: -8px;
    top: 6px;
  }
}

@include media-breakpoint-up(lg) {
  .column-count {
    &-2 {
      column-count: 2;
    }
    &-3 {
      column-count: 3;
    }
    &-2, &-3 {
      column-gap: 2rem;
      /*p {
        page-break-inside: avoid;
      }*/
    }
  }
}


@include media-breakpoint-down(xs) {
  h1 { font-size: 2rem; }
  h2 { font-size: 1.8rem; }
  h3 { font-size: 1.6rem; }
  h4 { font-size: 1.4rem; }
  h5 { font-size: 1.2rem; }
  h6 { font-size: 1rem; }
}

.article-title {
  display: inline-block;
  background: #242429;
  color: white;
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.8rem 1rem 0.75rem;
  line-height: 1;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: -20px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.32);
  z-index: 1600;
  max-width: 100%;
  @media (max-width: 460px) {
    margin-left: -12px;
  }
}

.credentials {
  display: flex;
  flex-wrap: wrap;
  &-record {
    display: flex;
    align-items: center;
    font-size: 11px;
    margin-right: 1rem;
    margin-bottom: 3px;
    span:first-child {
      border: 1px solid currentColor;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 10px;
      margin-right: 0.25rem;
    }
  }
}

.cookie {
  background-color: $secondary;
  padding: .5rem;
  &__centre {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__button {
    background-color: $primary;
    padding: 2px 4px;
  }
}


@include media-breakpoint-up(lg) {
  .col-pdf {
    max-width: 20%;
  }
}

#loading {
  position: absolute;
  background-color: white;
  z-index: 1000;
  top: -10px;
  left: -24px;
  right: -24px;
  bottom: -24px;
  padding-top: 100px;
  .loader {
    border: 6px solid lightgray; /* Light grey */
    border-top: 6px solid $primary; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 0.8s linear infinite;
    margin: 0 auto;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}

@include media-breakpoint-up(lg) {
  @for $i from 12 through 38 {
    .order-lg-#{$i} {
      order: $i;
    }
  }
}

@include media-breakpoint-down(xs) {
  .order-xs {
    @for $i from 0 through 10 {
      &-#{$i} { order: $i; }
    }
  }
}

ul, ol {
  padding-left: 0;
}


@include media-breakpoint-up(lg) {
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
}

.btn {
  &-release-control {
    @extend .btn, .btn-sm, .d-inline-flex, .align-items-center, .shadow-sm, .w-100, .w-lg-auto;
    justify-content: space-between;
    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
    }
  }
}

@include media-breakpoint-down(xs) {
  .block-inner-wrap {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 460px) {
  .container, .header-nav {
    padding-left: $mobile-gutter-width/2;
    padding-right: $mobile-gutter-width/2;
  }
  .row:not(.no-gutters) {
    margin-left: #{$mobile-gutter-width/2 * -1};
    margin-right: #{$mobile-gutter-width/2 * -1};
  }
  [class*=col-] {
    padding-left: $mobile-gutter-width/2;
    padding-right: $mobile-gutter-width/2;
  }
}

.bg-cream {
  background-color: #efe5d8;
}